export interface ISettingsThemeColors {
    primary: string
    secondary: string
    accent: string
    error: string
    info: string
    success: string
    warning: string
    view: string
    dialog: string
    checkout: string
    card: string
    group: string
    drawer: string
    input: string
    search: string
    button: string
    buttonText: string
    buttonStyledText: string
    buttonPrimaryText: string
    tool: string
    toolText: string
    toolStyledText: string
    toolPrimaryText: string
    text: string
    inverted: string
    caption: string
    inactive: string
    disabled: string
    divider: string
    outline: string
    banner: string
    bannerText: string
    toolbar: string
    toolbarText: string
    footer: string
    footerText: string
}

export interface ISettingsTheme {
    defaultTheme: string
    useThemes: boolean
    light: ISettingsThemeColors
    dark: ISettingsThemeColors
}

export interface ISettingsGlobal {
    refreshRate: number
    loader: string
    fullRedirect: boolean
    required2fa: string
    background: string
}

export interface ISettingsCheckout {
    width: number
    height: number
    showLogo: boolean
    showBrand: boolean
}

export interface ISettingsToolbar {
    elevation: number
    dense: boolean
}

export interface ISettingsView {
    width: number
    height?: number
    rounded: string
    elevation: number
    outlined?: boolean
    margin: number
}

export interface ISettingsDialog {
    width: number
    height: number
    rounded: string
    elevation: number
    closeInHead: string
    closeInFooter: string
    vertical: boolean
}

export interface ISettingsCard {
    rounded: string
    elevation: number
}

export interface ISettingsGroup {
    rounded: string
    elevation: number
    outlined: boolean
}

export interface ISettingsNotify {
    rounded: string
    elevation: number
    class: string
    border: string
}

export interface ISettingsButton {
    rounded: string
    elevation: number
    class: string
    size: string
    glyph: string
}

export interface ISettingsInput {
    rounded: string
    class: string
    dense: boolean
    shaped: boolean
}

export interface ISettingsFont {
    family: string
    style: string
    weight: string
    size: string
    lineHeight: string
    letterSpacing: string
}

export interface ISettingsBanner {
    background: string
}

export interface ISettingsStateBanner extends ISettingsBanner {
    done: string
    failed: string
}

export interface ISettingsBanners {
    ident: ISettingsStateBanner
    secondFactor: ISettingsBanner
    quest: ISettingsBanner
}

export interface ISettingsGraphs {
    success: string
    error: string
    normal: string
}

export interface ISettingsDashboard {
    rainbow: Array<string>
}

export interface ISettingsModules {
    reporting: boolean
    crypto: boolean
    aml: boolean
    processing: boolean
}

export interface ISettingsRegistration {
    selfRegistration: boolean
    allowActivateWithoutIdent: boolean
    initDashboard: Array<string>
    createOnRegister: Array<string>
    openOnRegister: Array<string>
    openOnIdent: Array<string>
}

export interface ISettingsUI {
    theme: ISettingsTheme
    global: ISettingsGlobal
    checkout: ISettingsCheckout
    header: ISettingsToolbar
    footer: ISettingsToolbar
    view: ISettingsView
    dialog: ISettingsDialog
    card: ISettingsCard
    group: ISettingsGroup
    notify: ISettingsNotify
    button: ISettingsButton
    tool: ISettingsButton
    input: ISettingsInput
    search: ISettingsInput
    font: ISettingsFont
    banners: ISettingsBanners
    graphs: ISettingsGraphs
    dashboard: ISettingsDashboard
    modules: ISettingsModules
    registration: ISettingsRegistration
    languages: Array<string>
    brandLogo?: string
    brandName?: string
}

export interface ISettingsCompany {
    companyName: string
    companyCode: string
    companyBaseCurrency: string
    companyAddress: string
    companyVAT: string
    brandName: string
    supportEmail: string
    supportPhone: string
    financeEmail: string
    financePhone: string
    complianceEmail: string
    companyHomeURL: string
    companyLoginURL: string
    companyDocsURL: string
    descriptor?: string
    companyCopyrights: string
    companyCopyrightsMini: string
    companyCopyrightsCheckout: string
    companyUISettings: ISettingsUI;
}

export interface ISettingsPack {
    company: ISettingsCompany
}

/* eslint-disable */
const settings: ISettingsPack = {
    company: {
        companyName: '',
        companyCode: '',
        companyBaseCurrency: 'EUR',
        companyAddress: '',
        companyVAT: '',
        brandName: '',
        supportEmail: '',
        supportPhone: '',
        financeEmail: '',
        financePhone: '',
        complianceEmail: '',
        companyHomeURL: '',
        companyLoginURL: '',
        companyDocsURL: '',
        companyCopyrights: '',
        companyCopyrightsMini: '',
        companyCopyrightsCheckout: '',
        companyUISettings: {
            theme: {
                defaultTheme: "dark",
                useThemes: false,
                light: {
                    primary: "#5378c3",
                    secondary: "#c6e3ff",
                    accent: "#5a5a5a",
                    error: "#F55F4B",
                    info: "#8EB4FF",
                    success: "#5FC572",
                    warning: "#FFC047",
                    view: "#F7F8FC",
                    dialog: "#ffffff",
                    checkout: "#ffffff",
                    card: "#ffffff",
                    group: "#ffffff",
                    drawer: "#5378c3",
                    input: "#ffffff",
                    search: "#c6e3ff",
                    button: "#5378c3",
                    buttonText: "#ffffff",
                    buttonStyledText: "#ffffff",
                    buttonPrimaryText: "#ffffff",
                    tool: "#c6e3ff",
                    toolText: "#676767",
                    toolStyledText: "#ffffff",
                    toolPrimaryText: "#ffffff",
                    text: "#333333",
                    inverted: "#ffffff",
                    caption: "#757575",
                    inactive: "#E7EDEA",
                    disabled: "#c4c4c4",
                    divider: "#c4c4c4",
                    outline: "#c4c4c4",
                    banner: "#8EB4FF",
                    bannerText: "#ffffff",
                    toolbar: "#F7F8FC",
                    toolbarText: "#333333",
                    footer: "#5a5a5a",
                    footerText: "#ffffff"
                },
                dark: {
                    primary: "#5378c3",
                    secondary: "#c6e3ff",
                    accent: "#5a5a5a",
                    error: "#F55F4B",
                    info: "#8EB4FF",
                    success: "#5FC572",
                    warning: "#FFC047",
                    view: "#F7F8FC",
                    dialog: "#ffffff",
                    checkout: "#ffffff",
                    card: "#ffffff",
                    group: "#ffffff",
                    drawer: "#5378c3",
                    input: "#ffffff",
                    search: "#c6e3ff",
                    button: "#5378c3",
                    buttonText: "#ffffff",
                    buttonStyledText: "#ffffff",
                    buttonPrimaryText: "#ffffff",
                    tool: "#c6e3ff",
                    toolText: "#676767",
                    toolStyledText: "#ffffff",
                    toolPrimaryText: "#ffffff",
                    text: "#333333",
                    inverted: "#ffffff",
                    caption: "#757575",
                    inactive: "#E7EDEA",
                    disabled: "#c4c4c4",
                    divider: "#c4c4c4",
                    outline: "#c4c4c4",
                    banner: "#8EB4FF",
                    bannerText: "#ffffff",
                    toolbar: "#F7F8FC",
                    toolbarText: "#333333",
                    footer: "#5a5a5a",
                    footerText: "#ffffff"
                }
            },
            global: {
                refreshRate: 30000,
                loader: "HOURGLASS",
                fullRedirect: false,
                required2fa: 'not',
                background: "linear-gradient(to bottom right, #6090ef, #5378c3)"
            },
            checkout: {
                width: 500,
                height: 700,
                showBrand: false,
                showLogo: true
            },
            header: {
                elevation: 0,
                dense: false
            },
            footer: {
                elevation: 0,
                dense: true
            },
            view: {
                width: 1500,
                rounded: "no",
                elevation: 0,
                margin: 10
            },
            dialog: {
                rounded: "sm",
                elevation: 3,
                closeInHead: "xs sm",
                closeInFooter: "",
                width: 400,
                height: 600,
                vertical: false
            },
            card: {
                rounded: "sm",
                elevation: 2
            },
            group: {
                rounded: "sm",
                elevation: 0,
                outlined: true
            },
            notify: {
                rounded: "sm",
                elevation: 3,
                class: "colored-border",
                border: "left"
            },
            button: {
                rounded: "sm",
                elevation: 0,
                class: "outlined",
                size: "large",
                glyph: "medium"
            },
            tool: {
                rounded: "sm",
                elevation: 0,
                class: "depressed",
                size: "medium",
                glyph: "medium"
            },
            input: {
                rounded: "no",
                class: "",
                dense: true,
                shaped: false
            },
            search: {
                rounded: "no",
                class: "outlined",
                dense: true,
                shaped: false
            },
            font: {
                family: 'Arial',
                style: '',
                weight: '',
                size: '',
                lineHeight: '',
                letterSpacing: '',
            },
            banners: {
                ident: {
                    background: "linear-gradient(270deg, #6090ef 0%, #5378c3 100%)",
                    done: "linear-gradient(270deg, #6090ef 0%, #5378c3 100%)",
                    failed: "linear-gradient(270deg, #6090ef 0%, #5378c3 100%)"
                },
                secondFactor: {
                    background: "linear-gradient(270deg, #6090ef 0%, #5378c3 100%)"
                },
                quest: {
                    background: "linear-gradient(270deg, #6090ef 0%, #5378c3 100%)"
                }
            },
            graphs: {
                success: "#51B94F, #A7F270",
                error: "#FF5A5A, #FFF6A5",
                normal: "#6090ef, #5378c3"
            },
            dashboard: {
                rainbow: [
                    "#1661A7",
                    "#0B29CA",
                    "#7867C0",
                    "#A462CC",
                    "#D85F5F",
                    "#F09D53",
                    "#8FDE50",
                    "#4DBC26",
                    "#007E1C",
                    "#00AE5B",
                    "#00C29F",
                    "#168CA7"
                ]
            },
            modules: {
                reporting: false,
                crypto: false,
                aml: false,
                processing: false,
            },
            registration: {
                selfRegistration: false,
                allowActivateWithoutIdent: false,
                initDashboard: [],
                createOnRegister: [],
                openOnRegister: [],
                openOnIdent: [],
            },
            languages: ['en'],
        }
    }
}
/* eslint-enable */

export default settings
