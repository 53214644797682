import settings from './plugins/settings'
import './plugins/clipboard'
import './plugins/qrcode'

async function starter() {
  const Vue = (await import('vue')).default
  const App = (await import('./App.vue')).default

  const store = (await import('./plugins/store')).default
  const vuetify = (await import('./plugins/vuetify')).default
  const router = (await import('./plugins/router')).default
  await import('./plugins/charts')

  const $ph = (await import('./plugins/phoenix')).default
  const PhoenixStart = (await import('./plugins/phoenix')).PhoenixStart

  const system = (await import('@/modules/system')).default
  const session = (await import('@/modules/session')).default
  const wallet = (await import('@/modules/wallet')).default

  PhoenixStart([system, session, wallet], false, router)

  Vue.config.productionTip = process.env.VUE_APP_ENV === 'PROD'

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    data: { $ph },
  }).$mount('#app')
}

let progress = 0
const loader = setInterval(() => {
  if (progress === 0) {
    document.title = '⣾'
  } else if (progress === 1) {
    document.title = '⣽'
  } else if (progress === 2) {
    document.title = '⣻'
  } else if (progress === 3) {
    document.title = '⢿'
  } else if (progress === 4) {
    document.title = '⡿'
  } else if (progress === 5) {
    document.title = '⣟'
  } else if (progress === 6) {
    document.title = '⣯'
  } else if (progress === 7) {
    document.title = '⣷'
  }
  progress++
  if (progress > 7) {
    progress = 0
  }
}, 300)

try {
  const xmlHttp = new XMLHttpRequest()
  xmlHttp.open('GET', '\\balancer\\company', false)
  xmlHttp.send(null)
  const data = JSON.parse(xmlHttp.responseText)
  if (xmlHttp.status !== 200) {
    if (data.errorCode) {
      document.getElementById('splash-error')!.innerText = data.errorCode
    } else {
      document.getElementById('splash-error')!.innerText = data.error
    }
  } else {
    settings.company = data.body
    settings.company.companyUISettings = JSON.parse(data.body.companyUISettings)

    clearInterval(loader)

    document.title = settings.company.brandName
    starter()
    document.getElementById('splash')!.style.display = 'none'
  }
} catch (err: any) {
  document.getElementById('splash-error')!.innerText = err
}
